import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  selectedStore: {},
  selectedAppointmentDate: "",
  selectedAppointmentSlot: "",
  stores: [],
  appointments: [],
  updateAppointment: {},
};

const walkInReducer = createSlice({
  name: "walkIn",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    setSelectedStore(state, action) {
      state.selectedStore = action.payload;
    },
    setSelectedAppointmentDateTime(state, action) {
      state.selectedAppointmentDate = action.payload.date;
      state.selectedAppointmentSlot = action.payload.slot;
    },

    callGetStoresApi(state, action) {
      state.stores = action.payload;
    },
    callGetStoresApiSuccess(state, action) {
      state.stores = action.payload;
    },
    callGetStoresApiFailure(state, action) {
      state.error = action.payload;
    },
    callGetAppointmentsApi(state, action) {
      state.appointments = action.payload;
    },
    callGetAppointmentsApiSuccess(state, action) {
      state.appointments = action.payload;
    },
    callGetAppointmentsApiFailure(state, action) {
      state.error = action.payload;
    },
    callUpdateAppointmentApi(state, action) {
      state.updateAppointment = action.payload;
    },
    callUpdateAppointmentApiSuccess(state, action) {
      state.updateAppointment = action.payload;
    },
    callUpdateAppointmentApiFailure(state, action) {
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = walkInReducer;
export const {
  resetStore,
  setSelectedStore,
  setSelectedAppointmentDateTime,
  callGetStoresApi,
  callGetAppointmentsApi,
  callUpdateAppointmentApi,
} = actions;
export default reducer;
