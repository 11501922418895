import {
    callGetAppointmentsApi,
    callGetStoresApi,
    callUpdateAppointmentApi,
  } from "../modules/RepairClaim/state/reducers/walkInReducer";
  
  export const getStores = (cacheId, deviceMake) => ({
    type: callGetStoresApi,
    meta: {
      async: true,
      blocking: true,
      path: "/screenrepair/v1/stores",
      method: "POST",
      body: {
        ASPStoresRequest: {
          SessionId: cacheId,
          DeviceMake: deviceMake,
        },
      },
    },
  });
  
  export const getAppointments = (cacheId, serviceProviderId) => ({
    type: callGetAppointmentsApi,
    meta: {
      async: true,
      blocking: true,
      path: "/screenrepair/v1/appointmentslots",
      method: "POST",
      body: {
        AppointmentSlotsRequest: {
          SessionId: cacheId,
          ServiceProviderId: serviceProviderId,
        },
      },
    },
  });
  
  export const updateAppointment = (
    UpdateRepairRequestParameter
  ) => ({
    type: callUpdateAppointmentApi,
    meta: {
      async: true,
      blocking: true,
      path: "/screenrepair/v1/updaterepairrequest",
      method: "POST",
      body: {
        UpdateRepairRequestParameter
      },
    },
  });