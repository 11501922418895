import { addJourneyMessages, sleep } from "../helpers/addJourneyMessages";
import createMessage from "../helpers/createMessage";
import {
  EnumEnquiryModules,
  INCIDENT_FAULT_REPAIR,
  INCIDENT_SCREEN_REPAIR,
  INCIDENT_SCREEN_REPAIR_WARRANTY,
  INCIDENT_BATTERY_REPLACEMENT,
} from "../components/EnquiryOptions/Constants";
import { initializePickupSchedule } from "../modules/RepairClaim/state/operators/scheduleOperator";
import {
  setEnquiryOption,
  setCaseType,
} from "../reducers/journeyMessagesReducer";
import {
  PROGRESS_DATA,
  API_PROGRESS,
  COMPONENTS,
  ACTIVITY,
  CLIENT,
  INCIDENT_PATH_TYPE,
} from "../helpers/constants";
import {
  updateFlowProgress,
  updateApiProgress,
  clearApiProgress,
  serviceUnavailable,
} from "./flowWindow";
import {
  cancelServiceRequest,
  determineRequest,
  resumeServiceRequest,
} from "./serviceRequestApi";
import updateVisitor from "../modules/Appsync/updateVisitor";
// import { createChatEnquiry } from "./cowrapperapi";
import { udpateChatInputVisibility } from "../modules/Appsync/state/operators";
import { setChatReason } from "../modules/Appsync/state/reducers";
import { isEmpty } from "../helpers/formatUtils";
import { setDeviceMakeAndModel } from "../modules/RepairClaim/state/actions/actions";
import { getEnrolledServiceFee, getServiceFee } from "./cowrapperapi";
import { getStores } from "./walkInApi";
import {
  displayRepairOption,
  saveRepairOptionType,
} from "../modules/RepairClaim/state/operators/RepairOptionsOperator";

import { setServiceFee } from "../modules/RepairClaim/state/reducers/paymentReducer";

const newRequestFlow = (labelMessage) => async (dispatch, getStore) => {
  const initial = getStore().journeyMessages.flowProgress.percentage;
  await dispatch(
    updateFlowProgress(
      PROGRESS_DATA.PREPARE_REQUIREMENTS.title,
      PROGRESS_DATA.PREPARE_REQUIREMENTS.count,
      initial
    )
  );
  dispatch(
    addJourneyMessages([
      labelMessage,
      createMessage("DEVICE_CONFIRMATION", "system", {
        showComponent: "preparation",
      }),
    ])
  );
};

const cancelPreviousSRInBackground = () => async (dispatch, getStore) => {
  // cancel SR
  await dispatch(
    updateApiProgress(API_PROGRESS.TROUBLESHOOT, 40, COMPONENTS.ENQUIRY_OPTIONS)
  );
  const { CacheId } = getStore().session.sessionData.Configurations;
  const { ServiceRequestId, CustomerCaseId } =
    getStore().serviceRequest.determineIncidentDetails;
  const note = "Request Cancelled by User";

  const cancelSRResponse = await dispatch(
    cancelServiceRequest(
      CacheId,
      ServiceRequestId,
      note,
      "",
      CustomerCaseId,
      "",
      ""
    )
  ).catch((err) => dispatch(serviceUnavailable()));
  if (isEmpty(cancelSRResponse)) return;

  const determineResponse = await dispatch(determineRequest(CacheId)).catch(
    (err) => dispatch(serviceUnavailable())
  );
  if (isEmpty(determineResponse)) return;

  await dispatch(
    updateApiProgress(
      API_PROGRESS.TROUBLESHOOT_SUCCESS,
      100,
      COMPONENTS.ENQUIRY_OPTIONS,
      40
    )
  );
  await sleep(1000);
  await dispatch(clearApiProgress());
};

const startChatFlow = (labelMessage, option) => async (dispatch) => {
  dispatch(addJourneyMessages([labelMessage]));
  await dispatch(setChatReason(option));
  dispatch(udpateChatInputVisibility(true));
};

const incidentTypeByName = {
  "NewRequest": INCIDENT_SCREEN_REPAIR,
  "NewRequestBatteryReplacement": INCIDENT_BATTERY_REPLACEMENT
}

const incidentTypeFromDetermineResponse = [
  EnumEnquiryModules.ResumeRequest,
  EnumEnquiryModules.CancelRequest
];

const getCaseType = (selectedOption, store) => {
  const isInWarranty = selectedOption === "In-WarrantyRequest";
  
  if (isInWarranty){
    const caseType = store.serviceRequest?.determineIncidentDetails?.Servicerequests?.[0]?.IncidentType;
    const isScreenRepair = caseType === INCIDENT_SCREEN_REPAIR;
    return isScreenRepair ? INCIDENT_SCREEN_REPAIR_WARRANTY : caseType;
  }

  const otherRequest = incidentTypeFromDetermineResponse.includes(selectedOption)
  if (otherRequest){
    const caseType = store.serviceRequest?.determineIncidentDetails?.ServiceRequest?.IncidentTypeCode;
    return caseType;
  }

  return incidentTypeByName[selectedOption];
}

export const initModuleTrigger = (option) => async (dispatch, getStore) => {
  console.log(option.name, "module is selected");
  const labelMessage = createMessage("TEXT", "user", option.label);
  dispatch(saveEnquiryOption(option.name));
  const client = getStore().app.client;
  const isSingtel = client === CLIENT.SINGTEL;
  const ClientProductSkuNbr = getStore().validation?.verifyAgreement?.VerifyAgreementsResults?.Agreement?.ClientProductSkuNbr;
  const isSingtelStarter = isSingtel && (ClientProductSkuNbr && ClientProductSkuNbr.toLowerCase().indexOf("starter") > -1)

  const incidentPathType = getStore().serviceRequest.type;

  let ServiceRequestType = getStore().serviceRequest?.determineIncidentDetails?.ServiceRequestType;
  let RepairRequest = getStore().serviceRequest?.determineIncidentDetails?.RepairRequest;
  let showComponent = "";
  let showModule = "";

  /*
  const { SubscriptionDetails: { MobileDeviceNumber, ProgramName }, ServiceFees } = action.payload.GetServiceFeeResponse;

      state.serviceFee = action.payload.GetServiceFeeResponse.ServiceFees.find(
        (f) => f.IncidentType === (window.location.href.toString().includes("/my") 
        ? INCEDENT_TYPES.FAULT_REPAIR 
        : ProgramName && ProgramName.toLowerCase().indexOf("starter") > -1 ? INCEDENT_TYPES.BATTERY_REPLACEMENT : INCEDENT_TYPES.SCREEN_REPAIR)
      ).Fees;
      
      state.serviceFees = { ...state.serviceFees, [MobileDeviceNumber]: ServiceFees, ProgramName }
      */

  
  const mobileNumber = getStore().validation?.inputData?.mdn;
  const serviceFees = getStore().claim?.payment?.serviceFees[mobileNumber];

  if (isSingtelStarter) {
    if (option.name === "NewRequestBatteryReplacement" || option.name === "In-WarrantyRequest") {
      dispatch(
        saveCaseType(INCIDENT_BATTERY_REPLACEMENT)
      );

      dispatch(setServiceFee(
        serviceFees?.find((f) => f.IncidentType === INCIDENT_BATTERY_REPLACEMENT.toUpperCase())?.Fees
      ))
    }
  } else {
    if (option.name === "NewRequest") {
      let ct = isSingtel ? INCIDENT_SCREEN_REPAIR : INCIDENT_FAULT_REPAIR;
      dispatch(
        saveCaseType(ct)
      );

      dispatch(setServiceFee(
        serviceFees?.find((f) => f.IncidentType === ct.toUpperCase())?.Fees
      ))
    } if (option.name === "NewRequestBatteryReplacement") {
      dispatch(
        saveCaseType(INCIDENT_BATTERY_REPLACEMENT)
      );

      console.log(serviceFees?.find((f) => f.IncidentType === INCIDENT_BATTERY_REPLACEMENT.toUpperCase())?.Fees)

      dispatch(
        setServiceFee(
          serviceFees?.find((f) => f.IncidentType === INCIDENT_BATTERY_REPLACEMENT.toUpperCase())?.Fees
        )
      )
    } else if (option.name === "In-WarrantyRequest") {
      const caseType = getCaseType(option.name, getStore());
      let ct = isSingtel ? caseType : INCIDENT_FAULT_REPAIR

      dispatch(
        saveCaseType(ct)
      );

      dispatch(setServiceFee(
        serviceFees?.find((f) => f.IncidentType === ct.toUpperCase())?.Fees
      ))
    }
  }

  dispatch(
    updateVisitor({
      lastActivity: `${option.name} ${ACTIVITY.ENQUIRY_OPTIONS}`,
      interactionType: option.name,
    })
  );

  // const incidentPathType = getStore().serviceRequest.type;

  // let ServiceRequestType = getStore().serviceRequest?.determineIncidentDetails?.ServiceRequestType;
  // let RepairRequest = getStore().serviceRequest?.determineIncidentDetails?.RepairRequest;
  // let showComponent = "";
  // let showModule = "";

  switch (option.name) {
    case EnumEnquiryModules.NewRequest:
    case EnumEnquiryModules.NewRequestBatteryReplacement:
      if (incidentPathType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST) {
        await dispatch(cancelPreviousSRInBackground());
      }
      // const {
      //   interactionData: {
      //     Interaction: {
      //       InteractionLineId,
      //       SessionData: { ClientId, ClientChannelId, ClientName },
      //     },
      //   },
      // } = getStore().session;
      // const { mdn } = getStore().validation.inputData;
      // dispatch(
      //   getEnrolledServiceFee(
      //     ClientId,
      //     ClientChannelId,
      //     ClientName,
      //     InteractionLineId,
      //     mdn
      //   )
      // );
      await dispatch(newRequestFlow(labelMessage));
      break;
    case EnumEnquiryModules.InWarrantyRequest:
      // if (isSingtel) {
      //   const {
      //     interactionData: {
      //       Interaction: {
      //         InteractionLineId,
      //         SessionData: { ClientId, ClientChannelId, ClientName },
      //       },
      //     },
      //   } = getStore().session;
      //   const { mdn } = getStore().validation.inputData;
      //   const { CacheId } = getStore().session.sessionData.Configurations;

      //   dispatch(
      //     getEnrolledServiceFee(
      //       ClientId,
      //       ClientChannelId,
      //       ClientName,
      //       InteractionLineId,
      //       mdn,
      //       CacheId
      //     )
      //   );

      //   dispatch(initializePickupSchedule());
      // } else {
      //   if (incidentPathType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST) {
      //     cancelPreviousSRInBackground();
      //   }
      // }
      if (incidentPathType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST) {
        await dispatch(cancelPreviousSRInBackground());
      }
      await dispatch(newRequestFlow(labelMessage));
      break;
    case EnumEnquiryModules.ChangeSchedule:
      // let { ServiceRequestType, RepairRequest } =
      //   getStore().serviceRequest.determineIncidentDetails;

      // let showComponent = "";
      // let showModule = "";
      if (
        incidentPathType === INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED &&
        ServiceRequestType !== "SCRNREPREQ" &&
        ServiceRequestType !== "SURREPREQ"
      ) {
        // device in-eligible for screen repair
        showModule = "DEVICE_CONFIRMATION";
        showComponent = "deviceNotEligible";
      // } else if (isSingtel && !isSingtelStarter) {
      //   await dispatch(startChatFlow(labelMessage, option.name));
      //   return;
      } else {
        if (
          RepairRequest &&
          RepairRequest.RepairRequestType === "PUR" &&
          RepairRequest.RepairStatus !==
            ("DCX STARTED" ||
              "RCX STARTED" ||
              "DCX COMPLETED" ||
              "RCX COMPLETED" ||
              "COMPLETED" ||
              "Cancelled by user" ||
              "CANCELLED")
        ) {
          await dispatch(
            initializePickupSchedule(
              true,
              RepairRequest.RepairStatus !== "SCHEDULED"
            )
          );

          showComponent = "";
          showModule = "REPAIR_DETAILS";
        } else if (
          RepairRequest &&
          RepairRequest.RepairRequestType === "WALKIN" &&
          (RepairRequest.RepairStatus === "RESERVED" ||
            RepairRequest.RepairStatus === "SCHEDULED")
        ) {
          showComponent = "";
          showModule = "REPAIR_DETAILS";
        } else if (
          RepairRequest &&
          (RepairRequest.RepairStatus !== "RESERVED" ||
            RepairRequest.RepairStatus !== "SCHEDULED")
        ) {
          // redirect to chat
          await dispatch(startChatFlow(labelMessage, option.name));
          return;
        }
      }
      dispatch(
        addJourneyMessages([
          labelMessage,
          createMessage(showModule, "system", { showComponent }),
        ])
      );
      break;
    case EnumEnquiryModules.CancelRequest:
      // RepairRequest =
      //   getStore().serviceRequest.determineIncidentDetails.RepairRequest;
      ServiceRequestType =
        getStore().serviceRequest.determineIncidentDetails.ServiceRequestType;

      showComponent = "";
      showModule = "";

      if (
        incidentPathType === INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED &&
        ServiceRequestType !== "SCRNREPREQ" &&
        ServiceRequestType !== "SURREPREQ"
      ) {
        // device in-eligible for screen repair
        showModule = "DEVICE_CONFIRMATION";
        showComponent = "deviceNotEligible";
      } else if (
        RepairRequest &&
        (RepairRequest.RepairStatus === "RESERVED" ||
          RepairRequest.RepairStatus === "SCHEDULED")
      ) {
        await dispatch(initializePickupSchedule(true));
        showComponent = "";
        showModule = "REPAIR_DETAILS";
      } else if (
        RepairRequest &&
        (RepairRequest.RepairStatus !== "RESERVED" ||
          RepairRequest.RepairStatus !== "SCHEDULED")
      ) {
        // redirect to chat
        await dispatch(startChatFlow(labelMessage, option.name));
        return;
      } else if (
        incidentPathType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST
      ) {
        showModule = "CANCEL_REPAIR";
        showComponent = "ConfirmRequestCancel";
      }

      dispatch(
        addJourneyMessages([
          labelMessage,
          createMessage(showModule, "system", { showComponent }),
        ])
      );
      break;
    case EnumEnquiryModules.ResumeRequest:
      const { CacheId } = getStore().session.sessionData.Configurations;
      const { ServiceRequestId, ClaimedAsset } =
        getStore().serviceRequest.determineIncidentDetails;

      // RepairRequest =
      //   getStore().serviceRequest.determineIncidentDetails.RepairRequest;

      dispatch(addJourneyMessages([labelMessage]));
      const module = API_PROGRESS.RESUME_REQUEST,
        module_success = API_PROGRESS.RESUME_REQUEST_SUCCESS,
        component = COMPONENTS.ENQUIRY_OPTIONS;
      await dispatch(updateApiProgress(module, 40, component));

      const resumeSRResponse = await dispatch(
        resumeServiceRequest(CacheId, ServiceRequestId)
      ).catch((err) => dispatch(serviceUnavailable()));
      if (isEmpty(resumeSRResponse)) return;

      if (!isEmpty(resumeSRResponse.ServiceRequestDetails.IncidentType)) {
        dispatch(
          saveCaseType(resumeSRResponse.ServiceRequestDetails.IncidentType)
        );

        dispatch(setServiceFee(
          serviceFees?.find((f) => f.IncidentType === resumeSRResponse.ServiceRequestDetails.IncidentType.toUpperCase())?.Fees
        ))
      }

      await dispatch(updateApiProgress(module_success, 100, component, 40));
      await sleep(1000);
      await dispatch(clearApiProgress());

      // redirect to resume points
      const { Actions, SRAssetCatalogName, SRAssetCatalogId } =
        resumeSRResponse.ServiceRequestDetails;

      if (isEmpty(Actions) || Actions === "CaptureIncidentDetails") {
        showComponent = "preparation";

        showModule = "DEVICE_CONFIRMATION";
      } else if (
        Actions === "SelectReplacementEquipment" ||
        Actions === "ConfirmShippingAddress" ||
        Actions === "CaptureShippingMethod" ||
        Actions === "OrderFulFillment" || 
        Actions === "CapturePaymentDetails"
      ) {
        // save asset details
        const deviceDetails = {
          AssetCatalog: {
            AssetCatalogId: SRAssetCatalogId,
            AssetCatalogName: SRAssetCatalogName,
          },
          Make: {
            MakeId: "",
            Name: ClaimedAsset.Make,
          },
          Model: {
            ModelId: "",
            Name: ClaimedAsset.Model,
          },
          IMEI: "",
        };
        dispatch(setDeviceMakeAndModel(deviceDetails));

        // calling service fee api here
        // let encodedPerilString = encodeURIComponent(`\"${INCIDENT_FAULT_REPAIR.toUpperCase()}\",\"${INCIDENT_SCREEN_REPAIR.toUpperCase()}\",\"${INCIDENT_SCREEN_REPAIR_WARRANTY.toUpperCase()}\"`);
        const { caseType } = getStore().journeyMessages;
        const caseName = caseType ? caseType : isSingtel ? INCIDENT_SCREEN_REPAIR : INCIDENT_FAULT_REPAIR;
        let encodedPerilString = encodeURIComponent(`\"${caseName.toUpperCase()}\"`);
        dispatch(getServiceFee(CacheId, ServiceRequestId, encodedPerilString));

        let progressTitle, progressCount;

        if (!isEmpty(RepairRequest)) {
          // save repair request type for resume case
          dispatch(saveRepairOptionType(RepairRequest.RepairRequestType));

          // check for PUR and WALKIN
          // if (!isSingtel && RepairRequest.RepairRequestType === "WALKIN") {
          if (RepairRequest.RepairRequestType === "WALKIN") {
            // show repair stores
            showComponent = "SelectRepairCenter";
            showModule = "WALK_IN";

            progressTitle = PROGRESS_DATA.WALK_IN.title;
            progressCount = PROGRESS_DATA.WALK_IN.count;

            await dispatch(
              updateApiProgress(
                API_PROGRESS.GET_STORE_LIST,
                40,
                COMPONENTS.STORE_LIST,
                0
              )
            );

            // API call to get device stores list
            const getStoresResponse = await dispatch(
              getStores(CacheId, ClaimedAsset.Make)
            ).catch((err) => dispatch(serviceUnavailable()));

            if (isEmpty(getStoresResponse)) {
              return;
            }

            // After API call
            await dispatch(
              updateApiProgress(
                API_PROGRESS.GET_STORE_LIST_SUCCESS,
                100,
                COMPONENTS.STORE_LIST,
                40
              )
            );
            await sleep(1000);
            await dispatch(clearApiProgress());
          } else {
            // show PUR component
            await dispatch(initializePickupSchedule());

            showComponent = isSingtel
              ? "changePickupAddress"
              : "confirmPickupAddress";

            showModule = "PICKUP_AND_DELIVERY";

            progressTitle = isSingtel
              ? PROGRESS_DATA.NEW_PICKUP_ADDRESS.title
              : PROGRESS_DATA.CONFIRM_PICKUP_ADDRESS.title;
            progressCount = isSingtel
              ? PROGRESS_DATA.NEW_PICKUP_ADDRESS.count
              : PROGRESS_DATA.CONFIRM_PICKUP_ADDRESS.count;
          }
        } else {
          if (isSingtel) {
            // show PUR component
            await dispatch(initializePickupSchedule());

            showComponent = "changePickupAddress";
            showModule = "PICKUP_AND_DELIVERY";

            progressTitle = PROGRESS_DATA.NEW_PICKUP_ADDRESS.title;
            progressCount = PROGRESS_DATA.NEW_PICKUP_ADDRESS.count;
          } else {
            // show repair options
            dispatch(displayRepairOption());
            return;
          }
          // dispatch(displayRepairOption());
          // return;
        }

        const initial = getStore().journeyMessages.flowProgress.percentage;
        dispatch(updateFlowProgress(progressTitle, progressCount, initial));
        dispatch(updateVisitor({ lastActivity: ACTIVITY.TERMS_N_CONDITIONS }));
      }

      dispatch(
        addJourneyMessages([
          createMessage(showModule, "system", { showComponent }),
        ])
      );

      break;
    case EnumEnquiryModules.GeneralEnquiry:
      dispatch(
        addJourneyMessages([
          labelMessage,
          createMessage("GENERAL_ENQUIRY", "system", {
            showComponent: "GeneralEnquiryOptions",
          }),
        ])
      );
      break;
    default:
      dispatch(addJourneyMessages([labelMessage]));
  }
};

const saveEnquiryOption = (option) => (dispatch) => {
  dispatch({
    type: setEnquiryOption.toString(),
    payload: option,
  });
};

const saveCaseType = (caseType) => (dispatch) => {
  dispatch({
    type: setCaseType.toString(),
    payload: caseType,
  });
};
